"use client";
import Image from 'next/image';
import Link from 'next/link';
import Button from '../components/button';
import { RxInstagramLogo } from "react-icons/rx";

const Footer = () => {
    return (
        <footer className="bg-[#F1F1F1] text-center p-4 md:p-8 rounded-t-3xl">
            {/* Logo */}
            <div className="flex justify-center mb-4 md:mb-6 cursor-pointer transition-transform transform hover:scale-105">
                <Link href="/" aria-label="Retour à la page d'accueil">
                    <Image
                        src="/assets/logohormelys1.webp"
                        alt="Logo du site Hormelys"
                        width={200}
                        height={100}
                    />
                </Link>
            </div>

            {/* Texte informatif */}
            <p className="text-black text-sm md:text-base lg:text-lg leading-relaxed mb-4 md:mb-6">
                Les informations de ce site relèvent de la naturopathie, une approche de santé globale et durable, qui ne se substitue en aucun cas à un avis médical ou aux soins prodigués par un professionnel de la santé. Ne modifiez ou n&apos;arrêtez jamais un traitement sans l&apos;avis de votre médecin ou spécialiste.
            </p>

            {/* Informations de contact */}
            <div className="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-4 mb-4 md:mb-6">
                <Link href="mailto:nathalia.laffont@gmail.com" className="text-black text-sm transition-transform transform hover:scale-105" aria-label="Envoyé moi un mail á contact@hormelys.com">contact@hormelys.com</Link>
                <span className="hidden md:block text-black mx-2">|</span> {/* Barre verticale uniquement visible sur grand écran */}
                <Link href="tel:+33685683059" className="text-black text-sm transition-transform transform hover:scale-105" aria-label="Contactez moi au 06 85 68 30 59">06 85 68 30 59</Link>
                <span className="hidden md:block text-black mx-2">|</span>
                <Link href="/contactez-moi" aria-label="Pour me contacter ou prendre un rendez-vous">
                    <Button shiny>
                        Contact
                    </Button>
                </Link>
                <span className="hidden md:block text-black mx-2">|</span>
                <Link href="https://www.instagram.com/nathalia_energie.vitalite?igsh=OTdhY3R0eHl5YTlm" target="_blank" rel="noopener noreferrer" aria-label="Suivez-moi sur Instagram">
                    <RxInstagramLogo className="text-black w-6 h-6 transition-transform transform hover:scale-105" />
                </Link>
            </div>

            {/* Localisation */}
            <p className="text-black text-sm md:text-base lg:text-lg leading-relaxed mb-4 md:mb-6">
                En cabinet au Pôle Santé de Gignac (34)<br />
                A domicile secteur Gignac, Pézenas, Clermont l&apos;Hérault<br />
                En visio
            </p>

            {/* Politique de confidentialité et mentions légales */}
            <p className="text-black text-sm">
                <Link href="/mentions-legales" className="hover:underline" aria-label="Prenez connaissance de notre politique de confidentialité">Politique de confidentialité</Link>
                <span className="mx-2 hidden md:inline-block">|</span> {/* Barre visible uniquement sur grand écran */}
                <Link href="/mentions-legales" className="hover:underline" aria-label="Nos mentions légales">Mentions légales</Link>
            </p>

            {/* Copyright */}
            <p className="text-black text-sm mt-4">
                Copyright 2025 Nathalia Laffont | Reproduction interdite | Site créé par{' '}
                <Link href="https://dlwebcreation.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                    dlwebcreation
                    <span className="ml-1" aria-hidden="true">🔗</span>
                </Link>
            </p>
        </footer>
    );
};

export default Footer;


