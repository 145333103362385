"use client";

import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import React from "react";

const animationProps = {
    initial: { "--x": "-100%", scale: 0.8 },
    animate: { "--x": "100%", scale: 1 },
    whileTap: { scale: 0.95 },
    transition: {
        repeat: Infinity,
        repeatType: "loop",
        repeatDelay: 1,
        type: "spring",
        stiffness: 20,
        damping: 15,
        mass: 2,
        scale: {
            type: "spring",
            stiffness: 200,
            damping: 5,
            mass: 0.5,
        },
    },
}

const Button = React.forwardRef(({
    children,
    className,
    onClick,
    shiny = false,
    ...props
}, ref) => {
    if (shiny) {
        return (
            <motion.button
                ref={ref}
                className={cn(
                    "relative rounded-3xl px-6 py-2 font-medium bg-[#B3BAA8] text-black",
                    "hover:scale-105 transition-transform duration-200 ease-in-out overflow-hidden",
                    className
                )}
                onClick={onClick}
                {...animationProps}
                {...props}
                style={{
                    "--primary": "0 0% 0%",
                }}
            >
                {children}
                <div
                    className="absolute inset-0 pointer-events-none"
                    style={{
                        background: "linear-gradient(90deg, transparent 0%, rgba(255,255,255,0.4) 50%, transparent 100%)",
                        transform: "translateX(var(--x))",
                    }}
                />
            </motion.button>
        );
    }

    // Button original si shiny=false
    return (
        <button
            ref={ref}
            className={`bg-[#B3BAA8] text-black px-6 py-2 text-sm lg:text-base hover:opacity-50 transition-opacity rounded-3xl ${className} 
            hover:scale-105 transition-transform duration-200 ease-in-out`}
            onClick={onClick}
            {...props}
        >
            {children}
        </button>
    );
});

Button.displayName = "Button";

export default Button;
